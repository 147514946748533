import { gql } from 'urql'

const QueryTenantMembers = gql`
  query (
    $limit: Int!
    $page: Int!
    $search: String
    $departmentIds: [Int!]
    $jobOwnable: Boolean
    $userReqPermisisons: Boolean
    $requisitionApprovers: Boolean
    $hiringMemberIds: [Int!]
    $recruiterIds: [Int!]
  ) {
    tenantMembers(
      limit: $limit
      page: $page
      search: $search
      departmentIds: $departmentIds
      jobOwnable: $jobOwnable
      hiringMemberIds: $hiringMemberIds
      recruiterIds: $recruiterIds
      userReqPermisisons: $userReqPermisisons
      requisitionApprovers: $requisitionApprovers
    ) {
      collection {
        id
        email
        fullName
        avatarVariants
        defaultColour
        roles {
          id
          name
          code
        }
        userTenants {
          openJobsCount
        }
        ownTenant
        departments {
          id
          name
          subordinates {
            id
            name
          }
          parent {
            id
            name
          }
        }
        locations {
          id
          name
        }
        allDepartments
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTenantMembers
